import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "antd";
import PropTypes from "prop-types";
import Cookies from "universal-cookie";

// redux
import { setIsCookiesAccepted } from "../../redux/auth/authActions";

// styles
import "../../assets/styles/shared/cookies-notification.scss";

const CookiesNotification = ({ setIsCookie }) => {
  const [isCookiesHidden, setIsCookiesHidden] = useState(true);

  const cookie = new Cookies();
  const cookiePolicyAccepted = cookie.get("cookiePolicyAccepted");

  useEffect(() => {
    if (cookiePolicyAccepted !== "true") {
      setIsCookiesHidden(false);
    }
  }, [cookiePolicyAccepted]);

  const handleAcceptCookie = useCallback(() => {
    setIsCookiesHidden(false);

    const currentDate = new Date();
    const expirationDate = new Date(
      currentDate.setFullYear(currentDate.getFullYear() + 5)
    );

    setIsCookie({ cookieValue: "true", expirationDate });
  }, []);

  if (isCookiesHidden) {
    return null;
  }

  return (
    <div className="cookies-notification">
      <div className="cookies-notification__label">
        This website uses cookies to give you a better experience. Please see
        our
        <Link to="/privacy-policy"> Cookie Policy </Link>
        for details
      </div>
      <Button
        onClick={handleAcceptCookie}
        className="cookies-notification--accept-btn"
      >
        Accept Cookies
      </Button>
    </div>
  );
};

CookiesNotification.propTypes = {
  setIsCookie: PropTypes.func.isRequired,
};

export default connect(null, {
  setIsCookie: setIsCookiesAccepted,
})(CookiesNotification);
